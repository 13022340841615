// 和后台请求的接口相关
import axios from 'axios';
import { getLanguage, /*detectOS, nPw */} from "@/common/tools.js";
import ERROR_CODE from "@/common/interface_api/custom_error_code.js";

// 服务器使用类型 (影响API请求)
const serviceMode =  window.appConfig.log ?"test"  :"offc";

const longConsle = window.appConfig.longConsole;

// API使用的URL路径
const api_datacenter_url = {
    url: "",
    default: window.appConfig.host + "/api", // 默认 API 请求URL
    setURL:function(newValue){
        this.url = newValue;
    },
    getURL:function(){
        return this.url ?this.url :this.default;
    }
}
// 基础 AXIOS 
    axios.defaults.withCredentials = true //开启后服务器才能拿到cookie
    axios.defaults.crossDomain = true;//配置axios跨域
    axios.defaults.headers.post['Content-Type'] = 'application/json; charset=UTF-8'//配置默认请求头
//

// 通用Axios函数
    // 通用axios Then函数 (API的key, 请求URL, API URL, 请求参数)
    function currAxiosThen (name, reqURL, apiUrl, apiData) {
        return resp => { // 正常请求回调
            if (resp.data.code == 401){
                throw resp.data;
            }
            if (serviceMode === "test") {
                if (typeof apiUrl === "string"){
                    if(longConsle){
                        console.log(
                            // (api.key) / (URL) / (API_URL)
                            `%c${ name ?`api.${name}\n` :"" }==> URL: ${reqURL}\n==> API: ${apiUrl}`,
                            (resp.data && resp.data.code == 200 ?"color: blue" :"color: red") + "; background: white;",          // 颜色
                            `\n==> 请求:`, apiData,                                                   // 请求数据
                            "\n==> 回调:", resp.data                                                  // 回调数据
                        )
                    } else {
                        console.log(
                            `%cCODE:${resp.data ?resp.data.code :"-"}\n${reqURL}${apiUrl}`,
                            (resp.data && resp.data.code == 200 ?"color: blue" :"color: red") + "; background: white;"
                        )
                    }
                } else {
                    if(longConsle){
                        console.log(
                            // (api.key) / 中文介绍 / (URL) / (API_URL)
                            `%c${ name ?`api.${name}\n` :"" }==> ${apiUrl[1]}\n==> URL: ${reqURL}\n==> API: ${apiUrl[0]}`,
                            (resp.data && resp.data.code == 200 ?"color: blue" :"color: red") + "; background: white;",          // 颜色
                            `\n==> 请求:`, apiData,                                                   // 请求数据
                            "\n==> 回调:", resp.data                                                  // 回调数据
                        )
                    } else {
                        console.log(
                            `%c${apiUrl[1]} CODE:${resp.data ?resp.data.code :"-"}\n${reqURL}${apiUrl[0]}`,
                            (resp.data && resp.data.code == 200 ?"color: blue" :"color: red") + "; background: white;"
                        )
                    }
                }
            }
            return resp.data
        }
    }
    // 通用axios Catch函数 (API的key, 请求URL, API URL, 请求参数)
    function currAxiosCatch (name, reqURL, apiUrl, apiData) {
        return err => { // => 401 
            if (serviceMode === "test") {
                if (typeof apiUrl === "string"){
                    console.log(
                        `%c${ name ?`api.${name}\n` :"" }==> URL: ${reqURL}\n==> API: ${apiUrl}`,
                        "color: red" + "; background: white;",
                        `\n===> 请求:`, apiData
                    )
                } else {
                    console.log(
                        `%c${ name ?`api.${name}\n` :"" }==> ${apiUrl[1]}\n==> URL: ${reqURL}\n==> API: ${apiUrl[0]}`,
                        "color: red" + "; background: white;",
                        `\n===> 请求:`, apiData
                    )
                }
                if(err.message){
                    console.warn(`===> ${apiUrl} POST 回调报错: `, err.message)
                } else {
                    console.warn(`===> ${apiUrl} POST 回调报错: `, err)
                }
            }
            throw err
            // var errorCode = err.message.split(" ")[err.message.split(" ").length-1];
            // if(err.code){
            //     errorCode = err.code;
            // }
            // if (ERROR_CODE[errorCode]) {
            //     throw new Error (ERROR_CODE[errorCode])
            // } else {
            //     throw new Error (`unknown_error : ${err.message}`)
            // }
        }
    }

// 基础函数
    // V1 版本 基础函数
    function postData (apiUrl, data, app_id, api_ver = "1.0.1", auth_type=1, acc_token="", name=""){
        const reqURL = api_datacenter_url.getURL();
        const url = reqURL + (typeof apiUrl === "string" ?apiUrl :apiUrl[0]);
        const apiData = {
            "params": data,
            "tag"   : "",
            "request":{
                "language" : getLanguage(),
                "app_key"  : app_id ?app_id :"",
                "acc_token": acc_token ?acc_token :(localStorage.acc_token?localStorage.acc_token :""),
                "api_ver"  : api_ver,
                "auth_type": auth_type ?auth_type :1,
            }
        }
        return axios.post(url, apiData)
            .then(currAxiosThen (name, reqURL, apiUrl, apiData))
            .catch(currAxiosCatch (name, reqURL, apiUrl, apiData));
    }
    // V2 版本 基础函数
    function postSimpleData (apiUrl, apiData, name=""){
        const reqURL = api_datacenter_url.getURL();
        const url = reqURL + (typeof apiUrl === "string" ?apiUrl :apiUrl[0]);
        let atherAxios = axios.create({ headers:{ Authorization:localStorage.acc_token } })
        return atherAxios.post(url, apiData)
            .then(currAxiosThen (name, reqURL, apiUrl, apiData))
            .catch(currAxiosCatch (name, reqURL, apiUrl, apiData));
    }
    // V2 新版本 基础函数
    function postSimpleBearerData (apiUrl, apiData, name=""){
        const reqURL = api_datacenter_url.getURL();
        const url = reqURL + (typeof apiUrl === "string" ?apiUrl :apiUrl[0]);
        let atherAxios = axios.create({ headers:{ Authorization:"Bearer "+ localStorage.acc_token } })
        return atherAxios.post(url, apiData)
            .then(currAxiosThen (name, reqURL, apiUrl, apiData))
            .catch(currAxiosCatch (name, reqURL, apiUrl, apiData));
    }

// API
let apiOBJ = {
    // 上传文件 (比较特殊)
    uploadFile(File){
        const reqURL = api_datacenter_url.getURL();
        let url = reqURL + "/v2/oss/upload";
        return axios.post(url, File)
            .then(resp => {
                if (serviceMode === "test") {
                    console.log(`upload POST 回调: `, resp.data)
                }
                return resp.data
            })
            .catch(err => {
                if (serviceMode === "test") {
                    console.error(`upload POST 回调报错: `, err)
                }
                var errorCode = err.message.split(" ")[err.message.split(" ").length-1];
                if (ERROR_CODE[errorCode]) {
                    throw new Error (ERROR_CODE[errorCode])
                } else {
                    throw new Error ("unknown_error")
                }
            });
    },
    uploadFile_PUT(File){
        // console.log(File);
        // console.log(arguments);
        let atherAxios = axios.create({ headers:{ Authorization:"Bearer "+ localStorage.acc_token } })
        return atherAxios.post(api_datacenter_url.getURL() + "/v2/oss/upload/puturl/get", {
            name: File.file.name,
            zone: File.zone,
            region: File.region,
        })
            .then(resp => {
                if (serviceMode === "test") {
                    console.log(`upload POST 回调: `, resp.data)
                }
                let uploadAxios = axios.create({
                    withCredentials: false,
                    headers:{
                        "Content-Type": "application/octet-stream",
                    },
                })
                return uploadAxios.put(resp.data.data.put_url, File.file)
                    .then(() => {
                        // console.log(data);
                        return resp.data;
                    })
                    .catch(e => {
                        console.error(e);
                    });
            })
            .catch(err => {
                if (serviceMode === "test") {
                    console.error(`upload POST 回调报错: `, err)
                }
                var errorCode = err.message.split(" ")[err.message.split(" ").length-1];
                if (ERROR_CODE[errorCode]) {
                    throw new Error (ERROR_CODE[errorCode])
                } else {
                    throw new Error ("unknown_error")
                }
            });
    },
    /*
        创建API (初版)
        * name : api[name] name 如果是 "other_api_" 开头则 401请求的时候不会跳转回login.html
        * url  : API请求接口 URL
        * app_id : 表头的 app_id 参数
        * api_ver: 表头的 api_ver
        * dataCheckFunc : 校验并补全请求参数
    */
    __createApi: function(name, url, dataCheckFunc, app_id ,api_ver) {
        let anname = ""
        if(typeof name !== "string"){
            [name, anname] = name; 
        }
        if(this[name]){
            console.error(`api[${name}]已存在`)
            return
        }
        this[name] = function(data) {
            var sendData = dataCheckFunc(data)
            if(anname !== ""){
                return postData([url, anname], sendData, app_id, api_ver, 1, "", name) 
            }
            return postData(url, sendData, app_id, api_ver, 1, "", name)
        }
    },
    // data.app_id 自动填充
    __createApiByAppId: function(name, url, dataCheckFunc) {
        let anname = ""
        if(typeof name !== "string"){
            [name, anname] = name; 
        }
        if(this[name]){
            console.error(`api[${name}]已存在`)
            return
        }
        this[name] = function(data) {
            var sendData = dataCheckFunc(data)
            if(anname !== ""){
                return postData([url, anname], sendData, data.app_id, "1.0.1", 1, "", name)
            }
            return postData(url, sendData, data.app_id, "1.0.1", 1, "", name)
        }
    },
    // data.app_id 自动填充; auth_type = 1; acc_token = data.acc_token
    __createApiByAppId_1: function(name, url, dataCheckFunc) {
        let anname = ""
        if(typeof name !== "string"){
            [name, anname] = name; 
        }
        if(this[name]){
            console.error(`api[${name}]已存在`)
            return
        }
        this[name] = function(data) {
            var sendData = dataCheckFunc(data)
            if(anname !== ""){
                return postData([url, anname], sendData, data.app_id, "1.0.1", 1, data.acc_token, name)
            }
            return postData(url, sendData, data.app_id, "1.0.1", 1, data.acc_token, name)
        }
    },
    // data.app_id 自动填充; auth_type = 4; acc_token = data.acc_token
    __createApiByAppId_4: function(name, url, dataCheckFunc) {
        let anname = ""
        if(typeof name !== "string"){
            [name, anname] = name; 
        }
        if(this[name]){
            console.error(`api[${name}]已存在`)
            return
        }
        this[name] = function(data) {
            var sendData = dataCheckFunc(data)
            if(anname !== ""){
                return postData([url, anname], sendData, data.app_id, "1.0.1", 4, data.acc_token, name) 
            }
            return postData(url, sendData, data.app_id, "1.0.1", 4, data.acc_token, name)
        }
    },
    // data.app_id 自动填充
    __createSimpleApi: function(name, url, dataCheckFunc) {
        let anname = ""
        if(typeof name !== "string"){
            [name, anname] = name; 
        }
        if(this[name]){
            console.error(`api[${name}]已存在`)
            return
        }
        this[name] = function(data) {
            var sendData = dataCheckFunc(data)
            if(anname !== ""){
                return postSimpleData([url, anname], sendData, name)
            }
            return postSimpleData(url, sendData, name)
        }
    },
    __createBearerApi: function(name, url, dataCheckFunc) {
        let anname = ""
        if(typeof name !== "string"){
            [name, anname] = name; 
        }
        if(this[name]){
            console.error(`api[${name}]已存在`)
            return
        }
        this[name] = function(data) {
            var sendData = dataCheckFunc(data)
            if(anname !== ""){
                return postSimpleBearerData([url, anname], sendData, name)
            }
            return postSimpleBearerData(url, sendData, name)
        }
    },
}
// 暴露方法 (TEST 限定)
if(serviceMode === "test"){
    window.$api = apiOBJ
    window.$postData = postData;
}
export {apiOBJ, postData, api_datacenter_url};