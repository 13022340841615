import { dataToSendData } from "@/common/interface_api/check_send.js";
export default function(api){
    api.__createBearerApi(["getLogFile", "IOT-日志文件列表"], "/v2/iot/admin/log/export/list", function(data){
        var sendData = dataToSendData(data, {
            strKey : ["uid"],
            numKey : ["gmt_end", "page_size","gmt_start", "page_no"],
        });
        return sendData
    })
    api.__createBearerApi(["getLog", "IOT-生成日志文件"], "/v2/iot/admin/log/export/add", function(data){
        var sendData = dataToSendData(data, {
            strKey : ["uid"],
            numKey : ["gmt_end","gmt_start"],
        });
        return sendData
    })
    api.__createApi(["uploadIotOrder", "IOT-xlsx上传"], "/iot/data/file/update" ,function(data){
        var sendData = dataToSendData(data, {
            strKey: ["link_id", "sys"],
            numKey: ["link_type", "gmt_start" ,"gmt_end",],
        });
        sendData.attrs = data.attrs;
        return sendData
    });

    api.__createApi(["getIotOrderXlsx", "IOT-获取xlsx数据"], "/iot/data/file/query" ,function(data){
        var sendData = dataToSendData(data, {
            strKey: ["sys", "import_start_time", "import_end_time"],
            numKey : ["state", "gmt_start", "gmt_end"],
        });
        sendData.attrs = data.attrs;
        return sendData
    });
    api.__createApi(["uploadDevOrder", "IOT-上传设备xlsx文件"], "/iot/dev/file/update" ,function(data){
        var sendData = dataToSendData(data, {
            strKey: ["file_url", "sys","key"],
            numKey : ["comp_id"],
        });
        return sendData
    });
    api.__createApi(["getDevListXlsx", "IOT-查询设备xlsx文件"], "/iot/dev/file/query" ,function(data){
        var sendData = dataToSendData(data, {
            strKey: ["import_start_time", "import_end_time"],
            numKey : ["state","page_no","page_size"],
        });
        return sendData
    });
    api.__createBearerApi(["getIotLog", "IOT-查询设备日志"], "/v2/iot/admin/log/list", function(data){
        var sendData = dataToSendData(data, {
            strKey : ["uid"],
            numKey : ["gmt_end", "page_size","gmt_start", "page_no"],
        });
        return sendData
    })
    api.__createBearerApi(["queryIotReg", "IOT-查询IOT注册列表"], "/v2/iot/web/uid/reg/list", function(data){
        var sendData = dataToSendData(data, {
            strKey : ["import_id","uid", "mac","pk"],
            numKey : ["page_size","comp_id", "page_no"],
        });
        return sendData
    })
    api.__createBearerApi(["queryIotRegDetail_1", "IOT-查询IOT注册详情(1)"], "/v2/iot/web/uid/info/get", function(data){
        var sendData = dataToSendData(data, {
            strKey : ["uid"],
            numKey : [],
        });
        return sendData
    })
    api.__createBearerApi(["queryIotRegDetail_2", "IOT-查询IOT注册详情(1)"], "/v2/iot/web/uid/properties/get", function(data){
        var sendData = dataToSendData(data, {
            strKey : ["uid"],
            numKey : [],
        });
        return sendData
    })

    api.__createBearerApi(["queryDevRegAna", "IOT-获取设备统计"],"/v2/iot/dev/statistics/get", function(data){
        var sendData = dataToSendData(data, {
            strKey : ["link_id"],
            numKey : ["gmt_start", "gmt_end", "link_type"],
        });
        return sendData
    })
    api.__createApi("getFeedbackDevice", "/iot/uid/reg/query" ,function(data){
        return dataToSendData(data, {
            strKey : ["uid"],
            numKey : [],
        });
    })
    api.__createBearerApi("getDeviceState", "/v2/umh/admin/dev/state/list" ,function(data){
        return dataToSendData(data, {
            strKey : ["sys", "srv_ip", "uid"],
            numKey : ["page_no", "page_size"],
        });
    })
    api.__createApi("getDevDup", "/iot/uid/duplicate/get" ,function(data){
        return dataToSendData(data, {
            strKey : ["uid", "mac"],
            numKey : [ "page_no", "page_size", "state"],
        });
    })
    api.__createApi("setDevDup", "/iot/uid/duplicate/set" ,function(data){
        return dataToSendData(data, {
            strKey : [],
            numKey : [ "id", "state"],
        });
    })
    // 添加新的设备状态导出
    api.__createBearerApi("getDevStateXls", "/v2/umh/admin/dev/state/export/add" ,function(data){
        return dataToSendData(data, {
            strKey : ["sys","link_id"],
            numKey : ["link_type"],
        });
    })
    // 获取设备状态xls
    api.__createBearerApi("getDevStateExport", "/v2/umh/admin/dev/state/export/list" ,function(data){
        return dataToSendData(data, {
            strKey : [],
            numKey : ["page_no", "page_size", "state"],
        });
    })
    // 查询唤醒状态
    api.__createApiByAppId("getWolState", "/iot/wol/state/get" ,function(data){
        let sendData = dataToSendData(data, {
            strKey : ["srv_ip","sys"],
            numKey : ["state","page_no","page_size"],
        });
        if(!data.uids){
            sendData.uids = []
        } else {
            sendData.uids = data.uids
        }
        return sendData;
    })
    api.__createApi("exportDevice", "/iot/uid/export/add",function(data){
        data.comp_id = data.company
        return dataToSendData(data, {
            strKey : ["pk"],
            numKey : ["gmt_start", "gmt_end", "comp_id"],
        });
    })
    api.__createApi("getExportDevice", "/iot/uid/export/get",function(data){
        return dataToSendData(data, {
            strKey : [],
            numKey : ["state","gmt_start", "gmt_end", "page_size", "page_no"],
        });
    })
}